import { Container } from '@monobits/components'
import { Bloks } from '../bloks'

const Legal = ({ blok, ...props }) => {
  const { body } = blok ?? {}

  const indexes =
    !!body.length &&
    body
      .filter((node) => node.component === 'legal-block' && !node.skip_index)
      .map(({ _uid }, i) => ({ _uid, index: i + 1 }))

  return (
    <Container variant="box" sx={{ mb: 4 }}>
      {!!body.length &&
        body.map((item) => {
          const index = indexes.filter((node) => node._uid === item._uid)[0]?.index || 0
          return <Bloks blok={item} index={index} {...props} key={item._uid} />
        })}
    </Container>
  )
}

export default Legal
