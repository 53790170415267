import { Container } from '@monobits/components'

import { Bloks } from '../bloks'
import { Intro } from '../components'

const Page = ({ blok, source: { slug }, ...props }) => {
  const { body } = blok ?? {}

  const content = body && body.map((item) => <Bloks blok={item} {...props} key={item._uid} />)

  return (
    <Container variant="box">
      {slug === 'home' && <Intro anchor={body?.[0]._uid} />}
      {content}
    </Container>
  )
}

export default Page
