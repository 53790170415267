import { SEO } from '../components'
import { useStoryblok } from '../hooks'
import Template from '../templates'

const BaseEntry = ({ pageContext, location, noindex = false, nofollow = false, ...props }) => {
  let { story, settings, locales } = useStoryblok(pageContext, location)

  const storyblok = { blok: story.content, source: story, settings, locales }

  const force_noindex = !!location.search ? true : null

  return (
    <>
      <SEO
        {...storyblok}
        pageContext={pageContext}
        noindex={force_noindex ?? noindex}
        nofollow={force_noindex ?? nofollow}
      />
      <Template
        {...storyblok}
        component={story.content.component}
        key={pageContext.story._uid}
        realPath={pageContext.realPath}
        lang={pageContext.lang.internal}
        location={location}
        {...props}
      />
    </>
  )
}

export default BaseEntry
