import { useState } from 'react'
import { Link } from 'gatsby'
import { Grid, Flex, useThemeUI } from 'theme-ui'
import { motion } from 'framer-motion'
import { Text, Button, Heading, Icon } from '@monobits/components'
import { Locomotion } from '@monobits/locomotive-scroll'
import { localize } from '@boiler/utils'

import { Bloks } from '../bloks'
import { BackToTop, Bio, CloudImage } from '../components'
import Linkedin from '../../assets/svg/linkedin.svg'

const Member = ({ blok, sx, locales: { locales, members }, settings, lang, ...props }) => {
  const { bio: variants } = useThemeUI().theme.transitions
  const {
    title,
    master,
    job_title,
    year_of_admission,
    languages,
    linkedin,
    email,
    phone,
    phone_id,
    fax,
    body,
    images: [, feature],
  } = blok ?? {}

  const [loaded, setLoaded] = useState(false)

  const backButton = (
    <Text as={Link} className="close" rte={false} to={'/' + settings?.member_back_link?.story?.full_slug}>
      {locales['retour']} [ X ]
    </Text>
  )

  const ready = loaded ? 'animate' : 'initial'

  return (
    <Grid as="section" sx={{ variant: 'member', ...sx }} {...props} data-sticky-container>
      <Locomotion sticky target="[data-sticky-container]">
        <Flex as="aside" sx={{ variant: 'member.image' }}>
          {backButton}
          {linkedin && (
            <Button
              as="a"
              variant="inverted"
              href={'https://www.linkedin.com/in/' + linkedin}
              target="_blank"
              className="social"
            >
              <Icon icon={Linkedin} sx={{ size: '1.5em' }} />
            </Button>
          )}
          <motion.div variants={variants.image} initial="initial" animate={ready}>
            <CloudImage
              url={feature.image.filename}
              alt={feature.image?.alt}
              onLoad={() => setLoaded(true)}
              options={{ lazyload: false, placeholder: false }}
            />
          </motion.div>
        </Flex>
      </Locomotion>

      <motion.article
        as="article"
        variant="box"
        sx={{ variant: 'layout.container.box', '&': { variant: 'member.container' } }}
        variants={variants.content}
        initial="initial"
        animate={ready}
      >
        <Flex as="nav" sx={{ variant: 'member.nav' }}>
          <Text as="h3" rte={false}>
            {localize(job_title, members)}
          </Text>
          <Locomotion sticky target="[data-sticky-container]">
            {backButton}
          </Locomotion>
        </Flex>
        <Heading as="h1" variant="name" sx={{ variant: 'member.name' }}>
          <Bio.Name master={master} name={title} locales={locales} />
        </Heading>
        <ul>
          {[
            <Bio.Phone phone={{ number: phone, ext: phone_id }} locales={locales} />,
            <Bio.Fax fax={fax} locales={locales} />,
            <Bio.Email email={email} title={settings?.meta_title} />,
          ].map((content, i) => (
            <Text as="li" key={'contacts' + blok._uuid + i}>
              {content}
            </Text>
          ))}
        </ul>
        <ul>
          {[
            year_of_admission && <Bio.Admission year={year_of_admission} locales={locales} />,
            !!languages.length && <Bio.Languages languages={languages} locales={{ ...locales, ...members }} />,
          ]
            .filter(Boolean)
            .map((content, i) => (
              <Text as="li" key={'details' + blok._uuid + i}>
                {content}
              </Text>
            ))}
        </ul>

        <Grid sx={{ variant: 'member.content' }}>
          {body && body.map((item) => <Bloks blok={item} locales={{ locales, members }} {...props} key={item._uid} />)}
        </Grid>
        <BackToTop sx={{ justifySelf: 'end' }} />
      </motion.article>
    </Grid>
  )
}

export default Member
