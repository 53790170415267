import { Section, Heading, Button } from '@monobits/components'
import { Link } from 'gatsby'

const NotFound = ({ blok, lang }) => {
  const { title, link_back_text } = blok ?? {}

  const root_path = lang === 'default' ? '/' : '/' + (lang ?? '')

  return (
    <Section
      variant="hero"
      sx={{
        '--mt': 'calc(var(--header-height) + 0.5em)',
        '--offset': 'calc(var(--mt) + (var(--padding-x) * 2))',

        display: 'grid',
        placeContent: 'center',
        gridGap: 1,
        p: 1,
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Heading>{title}</Heading>
      </div>
      <Button
        sx={{
          position: 'absolute',
          right: 1,
          bottom: 1,
        }}
        size="lg"
        as={Link}
        to={root_path}
      >
        {link_back_text}
      </Button>
    </Section>
  )
}

export default NotFound
