import { Container } from '@monobits/components'

import { Bloks } from '../bloks'
import Legal from './Legal'
import Page from './Page'
import Member from './Member'
import NotFound from './404'

const Template = ({ component, ...props }) => {
  const Component =
    {
      global: Bloks,
      page: Page,
      legal: Legal,
      member: Member,
      'not-found': NotFound,
    }[component] || Bloks

  if (component === 'global')
    return (
      <Container variant="box" data-inview="false">
        <Component {...props} />
      </Container>
    )

  return <Component {...props} />
}

export default Template
